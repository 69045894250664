import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "46",
  height: "46",
  viewBox: "0 0 46 46",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M42.9916 19.1915L45.5002 0.936279L27.245 3.44482L27.2147 3.4844L27.2101 3.47975L9.65576 21.034L25.4024 36.7807L42.9567 19.2264L42.9521 19.2217L42.9916 19.1915ZM36.7211 14.9622C35.2722 16.411 32.9232 16.411 31.4743 14.9622C30.0255 13.5133 30.0255 11.1643 31.4743 9.71541C32.9232 8.26656 35.2722 8.26656 36.7211 9.71541C38.1699 11.1643 38.1699 13.5133 36.7211 14.9622ZM25.5684 20.8681C26.5253 21.8249 28.0767 21.8249 29.0335 20.8681C29.9904 19.9112 29.9904 18.3598 29.0335 17.403C28.0767 16.4461 26.5253 16.4461 25.5684 17.403C24.6116 18.3598 24.6116 19.9112 25.5684 20.8681Z\" fill=\"#212529\"></path><path fill=\"#212529\" d=\"M19.2546 7.29842L11.948 7.30182L0.499896 18.7607L7.80643 18.7572L19.2546 7.29842Z\"></path><path fill=\"#212529\" d=\"M39.138 27.1816L39.1346 34.4881L27.6757 45.9363L27.6792 38.6297L39.138 27.1816Z\"></path><rect id=\"Rectangle 1075\" x=\"14.3193\" y=\"29.4935\" width=\"3.71001\" height=\"14.958\" transform=\"rotate(45 14.3193 29.4935)\" fill=\"#F06F02\"></rect><rect x=\"18.2544\" y=\"33.4285\" width=\"3.71001\" height=\"10.5032\" transform=\"rotate(45 18.2544 33.4285)\" fill=\"#F06F02\"></rect><rect x=\"10.3843\" y=\"25.5582\" width=\"3.71001\" height=\"10.5032\" transform=\"rotate(45 10.3843 25.5582)\" fill=\"#F06F02\"></rect>", 6)
  ])))
}
export default { render: render }